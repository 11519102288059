@import "media-queries";
.split-pane {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;

  &.split-small {
    grid-template-columns: 0.486fr;

    @media (screen and max-width: $breakpoint-xs) {
      grid-template-columns: 1fr;
    }
  }
}


@media (screen and max-width: $breakpoint-xs) {
  .split-pane {
    flex-direction: column;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
}
