.mat-mdc-menu-content {
 > .mat-mdc-menu-item, .mat-mdc-menu-item:visited, .mat-mdc-menu-item:link {
   color: var(--OGE_blue);
 }
}

.mat-mdc-menu-panel {
  box-shadow: var(--oge-box-shadow);
}

// is there are better solution?
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  --mat-menu-item-label-text-font: var(--OGE-gotham_light);
  --mat-menu-item-label-text-line-height: 20px;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-weight: 300px;
}