@import "media-queries";

.info-box {
  padding: 16px;
  background:var(--white);
  box-shadow: none;

  &.item-map {
    padding: 0;
  }
}


@media (screen and max-width: $breakpoint-xl) {
  .info-box {
    padding: 24px;
  }
}

@media (screen and max-width: $breakpoint-m) {
  .info-box {
    padding: 16px;
  }
}

@media (screen and max-width: $breakpoint-xm) {
  .info-box {
    padding: 24px;
  }
}