@import "media-queries";

.cdk-overlay-backdrop.blurred {
  backdrop-filter: blur(10px);
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  --mdc-dialog-container-elevation: var(--oge-box-shadow);
}

.form-dialog {
  .mat-mdc-dialog-container {
    & > .mdc-dialog__container {
      & > .mdc-dialog__surface {
        border-radius: 0;
        max-width: 98vw !important;
        width: 1200px;
        background-color: transparent;

        header {
          padding: 32px;
          color: var(--white);
          background: var(--OGE_mint1);

          .close-button {
            color: var(--white);
          }

          h3 {
            margin: 0;
          }
        }

        .mdc-dialog__content {
          padding: 32px;
          background-color: var(--white);

          & > form {
            margin-bottom: 0;

            &.is-second-page {
              margin-bottom: 0;
            }
          }
        }

        // ist 145 px hoch

        .mat-mdc-dialog-actions {
          padding: 32px 46px 32px 48px;
          justify-content: space-between;
          align-items: flex-end;
          // HOE=868 removed position
          // position: absolute;
          bottom: 0;
          width: 100%;
          border-top: 1px solid var(--oge-blue-5, rgba(20, 60, 108, 0.05));
          background: rgba(255, 255, 255, 0.90);

          .mdc-button {
            padding: 22px 24px !important;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .mat-mdc-dialog-container {
    .split-pane {
      gap: 16px 32px;
    }

    h4, .headline-gotham-black {
      margin: 16px 0 22px 0;
    }
  }

  .blured-map-background {
    background-image: url("../assets/backgrounds/blured-map-background.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}


@media (screen and max-width: ($breakpoint-m)) {
  .cdk-overlay-pane.mat-mdc-dialog-panel {
    --mat-dialog-container-max-width: 100vw;
  }
}

@media (screen and max-width: ($breakpoint-s)) {
  .form-dialog {
    .mat-mdc-dialog-container {
      & > .mdc-dialog__container {
        & > .mdc-dialog__surface {
          max-width: 100vw !important;
          width: 100vw !important;
          height: 91vh !important;

          .mdc-dialog__content {
            padding: 5px;

            & > form {
              margin-bottom: 40px;

              form.co2-form {
                margin-bottom: 0;
              }

              &.is-second-page {
                margin-bottom: 10px;
              }
            }
          }


          .mat-mdc-dialog-actions {
            display: grid;
            grid-template-columns: 1fr;
            padding: 10px 5px;
          }
        }
      }

      .checkbox-container {
        max-width: 100%;
      }
    }
  }
}
