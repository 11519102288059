@import "media-queries";

.table {
  height: 70vh;
  overflow: visible;

  .table-header {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 95px;

    .header-actions {
      justify-self: right;
      display: flex;
      align-items: baseline;
      padding-top: 26px;
      column-gap: 15px;
      .mat-mdc-button:not(:disabled), .mat-button.mat-icon {
        color: var(--OGE_blue, inherit);
      }

      mat-form-field {
        width: 350px;

        mat-label {
          color: var(--copy);
        }
      }
    }
  }

  .table-content {
    padding-bottom: 45px;
    .mat-expansion-panel-body {
      padding: 16px;
    }
  }

  .sort-section {
    overflow: auto;
    display: grid;
    align-items: center;
    margin-bottom: 24px;
    box-shadow: none;
    // padding-right: 18px;

    .mat-sort-header-arrow {
      color: var(--copy);
    }

    & > span {
      display: flex;
      color: var(--copy);

      &:last-child:not(.vnbLocationCity) {
        justify-self: flex-end;
      }

      &.vnbLocationCity {
        justify-self: center;
      }
    }
  }

  @media (screen and max-width: $breakpoint-m) {
    height: 60vh;

    .header-actions {
      flex-direction: column;
      align-items: flex-end;

      mat-form-field {
        margin: 0;
      }
    }
  }

  @media (screen and max-width: $breakpoint-xs) {
    .table {
      padding: 0;
      overflow: scroll;
    }
    .table-header {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      margin-bottom: 35px;

      .header-actions {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        row-gap: 10px;

        mat-form-field {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}
