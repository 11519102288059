// typography https://www.figma.com/file/yE2gsQQiW4jSPgd3Wl5INA/OGE-H2Connect?node-id=4043%3A30387&mode=dev
// default Gotham
@font-face {
  font-family: "Gotham Light";
  src:
    local(Gotham-Regular),
    url(/assets/fonts/gotham/GothamSSm-Light_Web.woff2) format('woff2');
}
@font-face {
  font-family: Gotham;
  src:
    local(Gotham),
    url(/assets/fonts/gotham/GothamSSm-Medium_Web.woff2) format('woff2');
}
@font-face {
  font-family: "Gotham Black";
  src:
    local(Gotham-Black),
    url(/assets/fonts/gotham/GothamSSm-Black_Web.woff2) format('woff2');
}

// backup Roboto
@font-face {
  font-family: Roboto;
  src:
    local(Roboto-Regular),
    url(/assets/fonts/Roboto/Roboto-Regular.woff2) format('woff2'),
    url(/assets/fonts/Roboto/Roboto-Regular.woff) format('woff'),
    url(/assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: Roboto;
  src:
    local(Roboto-Bold),
    url(/assets/fonts/Roboto/Roboto-Bold.woff2) format('woff2'),
    url(/assets/fonts/Roboto/Roboto-Bold.woff) format('woff'),
    url(/assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@mixin H1_Headline_Gotham_Black {
  font-family: 'Gotham Black', 'Gotham', 'Gotham Light', 'Roboto', sans-serif;
  font-size: 34px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;

}
@mixin H2_Subheader_Gotham {
  font-family: 'Gotham Light', 'Gotham', 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
}
@mixin H3_Headline_Gotham_Black {
  font-family: 'Gotham Black', 'Gotham','Gotham Light', 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
}
@mixin H4_Headline_Gotham_Black {
  font-family: 'Gotham Black', 'Gotham','Gotham Light', 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
@mixin Body2_Gotham {
  font-family: 'Gotham Light', Gotham, 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

}
@mixin Body1_Gotham {
  font-family: 'Gotham Light', Gotham, 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
@mixin Caption_Gotham {
  font-family: 'Gotham Light', 'Gotham', 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
@mixin Button_Gotham {
  font-family: 'Gotham', 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
@mixin Mini_Light_Gotham {
  font-family: 'Gotham Light','Gotham', 'Roboto', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;

}

$font_gotham: 'Gotham', 'Gotham Light', 'Roboto', sans-serif;
$font_gotham_light: 'Gotham Light', 'Gotham', 'Roboto', sans-serif;
$font_gotham_black: 'Gotham Black', 'Gotham', 'Gotham Light', 'Roboto', sans-serif;