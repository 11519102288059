// For the strange line on inputs
.mdc-notched-outline__notch {
  border-left: none !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  --mdc-filled-text-field-disabled-container-color: var(--white);
}

// For radio
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-unselected-icon-color: var(--copy);
  --mdc-radio-unselected-hover-icon-color: var(--OGE_blue);
  --mat-radio-disabled-label-color: var(--OGE_blue_30);
  --mdc-radio-disabled-selected-icon-color: var(--nav_dark_50);
  --mdc-radio-disabled-unselected-icon-color: var(--nav_dark_50);
}

// for checkbox
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-unselected-icon-color: var(--copy);
  --mdc-checkbox-unselected-hover-icon-color: var(--OGE_blue);
}