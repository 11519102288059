.mat-mdc-icon-button {
  --mat-icon-button-state-layer-color: transparent;

  &:disabled {
    --mdc-icon-button-disabled-icon-color: var(--OGE_blue_disabled);
  }
}

button[mat-flat-button].mat-mdc-unelevated-button {
  margin: 0;

  &.mat-primary {
    background-color: var(--OGE_pink1);
    &:hover {
      background-color: var(--OGE_pink_hover);
    }
    &:focus {
      background-color: var(--OGE_pink1);
      border: 2px solid var(--OGE_pink_hover);
    }
  }

  &.mat-accent {
    background-color: var(--OGE_mint2);
    &:hover {
      background-color: var(--OGE_mint1);
    }
    &:focus {
      background-color: var(--OGE_mint2);
      border: 2px solid var(--OGE_mint1);
    }
  }


  &:disabled {
    --mdc-filled-button-disabled-label-text-color: var(--OGE_blue_30);
    background-color: var(--OGE_blue_disabled);
  }
}

.mat-mdc-button {
  --mdc-text-button-label-text-font: 'Gotham Light', 'Gotham', 'Roboto', sans-serif;
  --mdc-text-button-label-text-size: 12px;
  --mdc-text-button-label-text-tracking: 0;
  --mdc-text-button-label-text-weight: 400;
  --mdc-filled-button-container-color: transparent;

}

.mat-button-toggle-appearance-standard, {
  --mat-standard-button-toggle-label-text-size: 12px;
}

.mdc-button {
  --mat-filled-button-state-layer-color: transparent;
}

/* ICONs */
.mat-mdc-menu-item {
  --mat-menu-item-icon-color: var(--OGE_blue)
}