.mat-button-toggle-appearance-standard {
  --mat-standard-button-toggle-selected-state-text-color: var(--nav_dark);
  --mat-standard-button-toggle-disabled-state-text-color: var(--OGE_blue_30);
  --mat-standard-button-toggle-text-font: var(--OGE-gotham_light);
  --mat-standard-button-toggle-height: 36px;
  --mat-standard-button-toggle-background-color: var(--OGE_blue_05);
  --mat-standard-button-toggle-selected-state-background-color: var(--OGE_blue_05);
}

.mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover .mat-button-toggle-focus-overlay,
.mat-button-toggle-focus-overlay {
  background-color: var(--white);
  opacity: 0;
}

.mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}