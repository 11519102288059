@import "media-queries";

.mat-expansion-panel {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 48px;
  margin-bottom: 24px;
  box-shadow: none !important;

  .mat-expansion-panel-header {
    padding: 0 16px 0 16px;
    background-color: var(--OGE_blue_05);
    border-radius: 0;

    &.mat-expanded:focus,
    &.mat-focus-indicator:focus {
      --mat-expansion-container-background-color: var(--OGE_blue_05);
      background-color: var(--OGE_blue_05);
    }

    &.mat-expanded:hover,
    &.mat-focus-indicator:hover {
      background-color: var(--OGE_blue_10);
      --mat-expansion-header-hover-state-layer-color: var(--OGE_blue_10);
    }

    .mat-expansion-panel-header-title {
      --mat-expansion-header-text-color: var(--OGE_blue);
    }

    .mat-expansion-indicator {
      &:after {
        color: var(--copy);
        margin-bottom: 7px;
      }
    }
  }

  .mat-expansion-panel-header-description {
    flex-grow: 1;
  }

  .mat-expansion-panel-body {
    padding: 16px;
    background-color: var(--white);
  }
}

@media (screen and max-width: ($breakpoint-xm)) {
  .mat-expansion-panel .mat-expansion-panel-body {
    padding: 5px 0;
  }

}

@media (screen and max-width: ($breakpoint-xs + 1)) {
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      padding: 5px 16px;
      min-height: 85px !important;

      &.mat-expanded {
        padding: 5px 16px;
        min-height: 95px !important;
      }
    }
  }
}