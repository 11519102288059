.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: var(--OGE-gotham_light);
  --mdc-plain-tooltip-container-color: var(--white);
  --mdc-plain-tooltip-supporting-text-color: var(--copy);
  --mdc-plain-tooltip-supporting-text-weight: normal;

  background-color: var(--white);
  padding: 8px;
  box-shadow: var(--oge-box-shadow);
  border-radius: var(--oge-border-radius);

  &::after {
    content: "";
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    background: var(--white);
    height: 25px;
    width: 12px;
    position: absolute;
    left: -12px;
    top: 40%;
  }
}